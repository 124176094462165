import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/Jumbotron"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVideo, faUsers, faHome } from "@fortawesome/free-solid-svg-icons"
import Video from "../components/Video"
import Fade from "react-reveal/Fade"
const Corporate = ({ data }) => {
  return (
    <Layout>
      <SEO title="Corporate Partnership" />
      <section id="corporate-partnership">
        <div className="container-fluid">
          <Jumbotron color="#e9f4fb">
            <h1 className="display-3 jumbotron-header">
              Does your company want to partner with Mike?
            </h1>
            <p className="lead">
              <strong>
                For the past 21 years, all on his own, Mike has taught these
                skills to hundreds of thousands of kids in more than 10,000
                classrooms.   Today, Mike seeks a corporate partner to share his
                vision and revolutionalize education. Imagine your company's
                name and logo reaching millions of families while bringing
                Mike's superhuman math and memory strategies to life for
                children everywhere. What if — all because your company made it
                happen — we can teach kids to process, memorize, focus and
                problem solve better than they ever thought possible?
              </strong>
            </p>
          </Jumbotron>
          <Jumbotron color="#d5f6e3">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="display-3 jumbotron-header">Who is Mike?</h1>
                <ul className="lead" style={{ fontWeight: 400 }}>
                  <li>
                    He has one of the fastest processing minds in the world!
                  </li>
                  <li>
                    What sets Mike apart from every other genius is his ability
                    to TEACH kids how they can learn and accomplish far beyond
                    what was thought possible.
                  </li>
                  <li>
                    Has spoken to thousands of classrooms over the past 21 years
                    (always for free)
                  </li>
                  <li>
                    Created a multi award winning product for home use called{" "}
                    <i>Brainetics</i> to teach kids and families how to empower
                    their minds, and hundreds of thousands of kids have and are
                    using it!
                  </li>
                  <li>
                    Has appeared on dozens of television and radio shows, both
                    national and local.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-3">
                <div className="text-center caption-text">
                  Mike on ABC's <i>20/20</i>
                </div>
                <Video
                  img={data.twentyTwenty.childImageSharp.fluid}
                  url="hv5QLyBr1CQ"
                ></Video>
              </div>

              <div className="col-lg-3 col-sm-3">
                <div className="text-center caption-text">
                  Mike on FOX Superhuman
                </div>
                <Video
                  img={data.superhuman.childImageSharp.fluid}
                  url="p_098lHOeDI"
                ></Video>
              </div>

              <div className="col-lg-3 col-sm-3">
                <div className="text-center caption-text">
                  Mike presenting at an assembly
                </div>
                <Video
                  img={data.assembly.childImageSharp.fluid}
                  url="nOCsGzF_BD4"
                ></Video>
              </div>

              <div className="col-lg-3 col-sm-3">
                <div className="text-center caption-text">
                  What teachers say about Mike
                </div>
                <Video
                  img={data.teachers.childImageSharp.fluid}
                  url="TxvYWD3xjgc"
                ></Video>
              </div>
            </div>
          </Jumbotron>
          <Jumbotron color="#f9d6d2">
            <h1 className="display-3 jumbotron-header">
              What we'll create together...
            </h1>
            <p className="lead">
              <strong>
                Mike's award-winning product, <i>Brainetics</i>, meant for use
                in the home, is now being used by thousands of teachers across
                the country. Mike wants to partner with a corporation to film
                and market a series of short, fun, and engaging videos designed
                for the classroom that teachers can use periodically throughout
                the school year (see example videos below). <br />
                <br />
                Instead of boring lecture videos on general focus and
                memorization techniques, these videos teach magic tricks, math
                shortcuts, and competitive problem-solving games, and more. By
                playing these team games and practicing impressive tricks to wow
                family and friends, students improve on the below skills, all
                while <strong>having fun</strong> in the process. In only 45
                minutes each week for 3 months, every student will have:
              </strong>
            </p>
            <ul>
              <li>Razor-sharp focus</li>
              <li>Improved direction-following skills</li>
              <li>Greatly-improved problem-solving skills</li>
              <li>A mega memory and amazing recall</li>
              <li>Improved academic confidence</li>
            </ul>
            <p className="lead">
              <strong>
                Every video and take-home worksheet will be branded with your
                logo, and Mike will promote our joint venture as he speaks at
                assemblies, TV, and radio shows across the country.
                <br />
              </strong>
            </p>

            <div className="row">
              <div className="col-lg-3 col-sm-3 offset-sm-3">
                <div className="text-center caption-text">
                  Thirteen Colonies Mnemonic Device
                </div>
                <Video
                  img={data.thirteenColonies.childImageSharp.fluid}
                  url="WBBVdcBbEsE"
                ></Video>
              </div>

              <div className="col-lg-3 col-sm-3">
                <div className="text-center caption-text">
                  Squaring Numbers
                  <br />
                  in the 50s
                </div>
                <Video
                  img={data.squaringNumbers.childImageSharp.fluid}
                  url="ND-UrP-tT2A"
                ></Video>
              </div>
            </div>
          </Jumbotron>

          <section id="services">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="section-heading">How The System Works</h2>
                  <hr className="my-4" />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center">
                  <div className="service-box mt-5 mx-auto">
                    <Fade>
                      <FontAwesomeIcon
                        className="fa-4x fa-users  text-primary mb-3"
                        icon={faVideo}
                      ></FontAwesomeIcon>
                    </Fade>
                    <h3 className="mb-3">Watch videos in the classroom</h3>
                    <p className="text-muted mb-0">
                      Each video is short and teaches an interactive, engaging
                      exercise for the entire class to do.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="service-box mt-5 mx-auto">
                    <Fade delay={500}>
                      <FontAwesomeIcon
                        className="fa-4x fa-users  text-primary mb-3"
                        icon={faUsers}
                      ></FontAwesomeIcon>
                    </Fade>
                    <h3 className="mb-3">
                      Play a competitive team game in class
                    </h3>
                    <p className="text-muted mb-0">
                      By practicing these fun exercises, students improve focus,
                      concentration, memory, confidence, and more.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="service-box mt-5 mx-auto">
                    <Fade delay={1000}>
                      <FontAwesomeIcon
                        className="fa-4x fa-users  text-primary mb-3"
                        icon={faHome}
                      ></FontAwesomeIcon>
                    </Fade>
                    <h3 className="mb-3">Teach family and friends at home</h3>
                  </div>
                  <p className="text-muted mb-0">
                    This is not only a great way to reinforce and practice the
                    material, but with custom-branded take-home worksheets, it
                    also means more exposure to your brand.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <Fade>
              <div className="col-lg-12">
                <h2 className="section-heading text-center">
                  Join us in our goal to change millions of lives.
                  <br />
                </h2>
                <p className="text-center">
                  Head to our <Link to="/contact">contact page</Link> to get in
                  touch.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Corporate

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    twentyTwenty: file(relativePath: { eq: "2020.png" }) {
      ...fluidImage
    }
    superhuman: file(relativePath: { eq: "superhuman.png" }) {
      ...fluidImage
    }
    assembly: file(relativePath: { eq: "assembly.png" }) {
      ...fluidImage
    }
    teachers: file(relativePath: { eq: "teachers.png" }) {
      ...fluidImage
    }
    thirteenColonies: file(relativePath: { eq: "thirteen-colonies.png" }) {
      ...fluidImage
    }
    squaringNumbers: file(relativePath: { eq: "squaring-numbers.png" }) {
      ...fluidImage
    }
  }
`
