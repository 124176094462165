import React from "react"

export default function Jumbotron({ color, children }) {
  return (
    <div className="row">
      <div className="col-sm-12 sr-corporate">
        <div
          className="jumbotron jumbotron-fluid"
          style={{
            marginBottom: "0px",
            backgroundColor: color,
            boxShadow: "0 0 5px 5px white inset",
          }}
        >
          <div className="container">{children}</div>
        </div>
      </div>
    </div>
  )
}
